@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">ข้อมูลผู้สมัคร</h1>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <span>รูป Profile</span><br />
        <v-btn @click="changePicProfile()" class="mt-3"> เลือกรูป </v-btn>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>รูป Profile</span>
        <v-img
          v-if="showImageProfile"
          :src="showImageProfile"
          height="300"
          width="300"
          alt="Selected Image Preview"
        />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.email"
          label="Email"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>

      
      <v-col cols="12" md="6" sm="12"> </v-col>
      <!-- <v-col cols="12" md="4" sm="12">
        <v-text-field
          v-model="form.password"
          label="Password"
          outlined
          dense
          hide-details="auto"
          type="password"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-text-field
          v-model="form.confirmPassword"
          label="Confirm Password"
          outlined
          dense
          hide-details="auto"
          type="password"
        ></v-text-field>
      </v-col> -->
      
      <!-- <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.shopName"
          label="ชื่อร้าน"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col> -->
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.shopDesc"
          label="ข้อมูลร้าน (แบบย่อ)"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="mb-n5">
        <v-autocomplete
          v-model="form.title"
          :items="titleItems"
          item-value="name"
          item-text="name"
          label="คำนำหน้า"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="0" md="9" sm="0"> </v-col>
      <v-col cols="12" md="3" sm="12">
        <br />
        <v-text-field
          v-model="form.firstName"
          label="ชื่อจริง"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <br />
        <v-text-field
          v-model="form.lastName"
          label="นามสกุล"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>วันเดือนปีเกิด</span>
        <!-- <v-text-field
          v-model="form.birthDate"
          label="วัน เดือน ปีเกิด"
          outlined
          dense
          hide-details="auto"
        ></v-text-field> -->
        <v-menu
          v-model="menuBirthDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.birthDate"
              label="วัน เดือน ปีเกิด"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              solo
              clearable
              @click:clear="form.birthDate = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.birthDate"
            @input="menuBirthDate = false"
            @click="$refs.menuBirthDate.save(form.birthDate)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <!-- <v-col cols="12" md="6" sm="12">
        <span>จังหวัดที่สมัครให้บริการ</span>
        <v-autocomplete
          v-model="form.province"
          label="เลือกจังหวัด"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>พื้นที่เขตที่สะดวกให้บริการ</span>
        <v-autocomplete
          v-model="form.area"
          label="เลือกเขต"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col> -->

      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.tel"
          label="เบอร์โทรศัพท์"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.lineId"
          label="ไอดีไลน์"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.currentJob"
          label="อาชีพปัจจุบัน"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.locationCurrentJob"
          label="สถานที่ทำงานปัจจุบัน"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <div style="color: red; font-size: 12px">
          หมายเหตุ: ต้องเตรียมเอกสาร
          สำเนาบัตรประชาชน/สำเนาทะเบียนบ้าน/หน้าสมุดบัญชีธนาคาร/เอกสารการฉีดวัคซีน(ถ้ามี)
        </div>
        <v-text-field
          v-model="form.idCardNumber"
          label="เลขบัตรประชาชน"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <span>บัตรประชาชน</span><br />
        <v-btn @click="changePic1()" class="mt-3"> เลือกรูป </v-btn>
        <div style="color: red; font-size: 12px">
          **เอกสารต้องอ่านข้อความได้ชัดเจน
        </div>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <span>ทะเบียนบ้าน</span><br />
        <v-btn @click="changePic2()" class="mt-3"> เลือกรูป </v-btn>
        <div style="color: red; font-size: 12px">
          **เอกสารต้องอ่านข้อความได้ชัดเจน
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12" class="mt-n6">
        <span>บัญชีธนาคาร</span>
        <v-text-field
          v-model="form.bankAccount"
          label="บัญชีธนาคาร"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.bankBranch"
          label="สาขา"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.bankName"
          label="ชื่อบัญชี"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.bankNo"
          label="เลขบัญชี"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <span>บัญชีธนาคาร</span><br />
        <v-btn @click="changePic3()" class="mt-3"> เลือกรูป </v-btn>
        <div style="color: red; font-size: 12px">
          **เอกสารต้องอ่านข้อความได้ชัดเจน
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>คุณได้รับการฉีดวัคซีนแล้วหรือไม่</span>
        <v-autocomplete
          v-model="form.vaccinated"
          :items="['ฉีดแล้ว', 'ยังไม่ฉีด']"
          label="คุณได้รับการฉีดวัคซีนแล้วหรือไม่"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>

      <!-- <v-col cols="12" md="2" sm="12">
        <span>โรคประจำตัว</span>
        <v-autocomplete
          v-model="form.medicalCondition"
          label="กรุณาเลือก"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col> -->
      <v-col cols="12" md="4" sm="12">
        <span>มีประสบการณ์อาบน้ำหมาแมวมาก่อนหรือไม่</span>
        <v-autocomplete
          v-model="form.didShower"
          :items="['มี', 'ไม่มี']"
          item-value="name"
          item-text="name"
          label="กรุณาเลือก"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <div style="color: red; font-size: 10px">
          <span style="color: black">เอกสารการฉีดวัคซีน</span>
          **หากไม่ได้รับการฉีดวัคซันไม่ต้องแนบก็ได้
        </div>
        <v-btn @click="changePic4()"> เลือกรูป </v-btn>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.contactTel"
          label="เบอร์ติดต่อ"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <span>Google Maps</span>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.lat"
          label="Latitude"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.lng"
          label="Longtitude"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <span>Line Notify Token <a href="https://notify-bot.line.me/my/" target="_blank">สร้างที่นี่</a></span>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          v-model="form.lineNotifyId"
          label="Line Notify Token"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <span>รูปบัตรประชาชน</span>
        <v-img
          v-if="showImage1"
          :src="showImage1"
          alt="Selected Image Preview"
        />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>รูปทะเบียนบ้าน</span>
        <v-img
          v-if="showImage2"
          :src="showImage2"
          alt="Selected Image Preview"
        />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>รูปสมุดบัญชี</span>
        <v-img
          v-if="showImage3"
          :src="showImage3"
          alt="Selected Image Preview"
        />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <span>รูปหลักฐานการฉีดวัคซีน</span>
        <v-img
          v-if="showImage4"
          :src="showImage4"
          alt="Selected Image Preview"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-end">
        <v-btn @click="goToBack()">back</v-btn> &emsp;
        <v-btn color="primary" @click="submit()">save</v-btn>
      </v-col>
    </v-row>
    <input
      type="file"
      ref="image"
      id="picProfile"
      accept="image/*"
      @change="showPictureProfile($event)"
      style="display: none"
      hidden
    />
    <input
      type="file"
      ref="image"
      id="picTure1"
      accept="image/*"
      @change="showPicture1($event)"
      style="display: none"
      hidden
    />
    <input
      type="file"
      ref="image"
      id="picTure2"
      accept="image/*"
      @change="showPicture2($event)"
      style="display: none"
      hidden
    />
    <input
      type="file"
      ref="image"
      id="picTure3"
      accept="image/*"
      @change="showPicture3($event)"
      style="display: none"
      hidden
    />
    <input
      type="file"
      ref="image"
      id="picTure4"
      accept="image/*"
      @change="showPicture4($event)"
      style="display: none"
      hidden
    />
  </v-card>
</template>
<script>
import { Encode } from "@/services";
import { Decode } from "@/services";
export default {
  data() {
    return {
      titleItems: [{ name: "นาย" }, { name: "นางสาว" }, { name: "นาง" }],
      menuBirthDate: false,
      form: {},
      picProfile: [],
      picture_profile: "",
      showImageProfile: "",
      imageNameProfile: "",
      pic1: [],
      picture_1: "",
      showImage1: "",
      imageName1: "",
      pic2: [],
      picture_2: "",
      showImage2: "",
      imageName2: "",
      pic3: [],
      picture_3: "",
      showImage3: "",
      imageName3: "",
      pic4: [],
      picture_4: "",
      showImage4: "",
      imageName4: "",

      checkEmail: false,
    };
  },
  created() {
    const checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("DDLotAdmin"))
    );
    // this.form = checkuser.user;
    // console.log("this.form", this.form);
    this.getInfo();
    this.showImage1 = this.form.picIdCard;
    this.showImage2 = this.form.picHouseRegis;
    this.showImage3 = this.form.picBookBank;
    this.showImage4 = this.form.picVaccinatedDoc;
    this.showImageProfile = this.form.profileImage;
  },
  async mounted() {},
  methods: {
    async getInfo() {
      const checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("DDLotAdmin"))
    );
    let response = {};
    if(!this.$router.currentRoute.query.id){
      response = await this.axios.get(
        `${process.env.VUE_APP_API}/partners/${checkuser.user.id}`
        // 'http://localhost:8081/categories/'
      );
    }else{
      response = await this.axios.get(
        `${process.env.VUE_APP_API}/partners/${this.$router.currentRoute.query.id}`
      );
    }
      
      console.log("get partner info", response.data);
      this.form = response.data.data;
    },
    goToBack() {
      this.$router.go(-1);
    },
    // showPicture1(e) {
    //   // console.log('index',index);
    //   const files = e.target.files;
    //   if (files[0] !== undefined) {
    //     this.imageName1 = files[0].name;
    //     const element = files[0];
    //     const reader = new FileReader();
    //     reader.readAsDataURL(element);
    //     reader.onload = () => {
    //       this.pic1 = reader.result;
    //       this.showImage1 = URL.createObjectURL(element);
    //     };
    //   }
    // },
    
    changePicProfile() {
      document.getElementById("picProfile").click();
    },
    showPictureProfile(e) {
      const files = e.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.picProfile = reader.result;
          this.showImageProfile = URL.createObjectURL(files[0]);
          this.form.profileImage = this.picProfile;
        };
      }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(files[0]);
          this.form.picIdCard = this.pic1;
        };
      }
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(files[0]);
          this.form.picHouseRegis = this.pic2;
        };
      }
    },
    showPicture3(e) {
      const files = e.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.pic3 = reader.result;
          this.showImage3 = URL.createObjectURL(files[0]);
          this.form.picBookBank = this.pic3;
        };
      }
    },
    showPicture4(e) {
      const files = e.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.pic4 = reader.result;
          this.showImage4 = URL.createObjectURL(files[0]);
          this.form.picVaccinatedDoc = this.pic4;
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
    RemoveImage1(index, item) {
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage1 = "";
      this.imageName1 = "";
    },
    RemoveImage2(index, item) {
      this.pic2 = [];
      this.picture_2 = "";
      this.showImage2 = "";
      this.imageName2 = "";
    },
    RemoveImage3(index, item) {
      this.pic3 = [];
      this.picture_3 = "";
      this.showImage3 = "";
      this.imageName3 = "";
    },
    RemoveImage4(index, item) {
      this.pic4 = [];
      this.picture_4 = "";
      this.showImage4 = "";
      this.imageName4 = "";
    },
    cancel() {
      this.$router.push("ManageDealer");
    },
    isValidEmail() {
      const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (mailformat.test(this.form.email)) {
        this.checkEmail = true;

        this.checkPasswrod();
      } else {
        this.checkEmail = false;

        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: "E-mail ไม่ถูกต้อง",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },

    checkPasswrod() {
      if (this.form.password === this.form.confirmPassword) {
        this.submit();
      } else {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: "Password ไม่ตรงกัน",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    async submit() {
      // var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      // const auth = {
      //   headers: { Authorization: `Bearer ${user.token}` },
      // };
      // console.log("userdata", user);
      const data = {
        ...this.form,
        // picIdCard: this.pic1.length ? this.pic1 : "",
        // picHouseRegis: this.pic2.length ? this.pic2 : "",
        // picBookBank: this.pic3.length ? this.pic3 : "",
        // picVaccinatedDoc: this.pic4.length ? this.pic4 : "",
      };
      console.log("data", data);
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.put(
            `${process.env.VUE_APP_API}/partners/${this.form.id}`,
            data
            // auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            const responseUser = await this.axios.get(
              `${process.env.VUE_APP_API}/partners/${this.form.id}`
            );
            // localStorage.setItem(
            //   "DDLotAdmin",
            //   Encode.encode({ user: responseUser.data.data })
            // );

            this.$swal.fire({
              icon: "success",
              text: `แก้ไขข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            // await location.reload();
            // this.$router.push("/");
            // this.$router.go(0);
          }
        }
      });
    },
  },
};
</script>
